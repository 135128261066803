import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AppLayout from '../Layouts/AppLayout';
import ThemeInput, { ThemeCodeInput } from '../components/Theme/ThemeInput';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { ROUTES, validation, validationText } from '../utils/app-constants';
import ThemeFileUpload from '../components/Theme/ThemeFileUpload';
import FooterSaveButton from '../components/Theme/FooterButton';
import TopBarCustom from '../components/TopBarCustom';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleBackButton } from '../utils/helper';

const BecomeAdmin = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState(null)
    const [phone, setPhone] = useState(null)

    const onSubmit = (data) => {
        setLoading(true)
        setTimeout(() => {
            navigate(ROUTES.adminSuccess)
            setLoading(false)
        }, 1000)
    };




    const handleOtp = () => {
        console.log(otp);
    }
    return (
        <div className='bg-light-pink'>
            <TopBarCustom text={"Become Admin"} onClick={() => handleBackButton(location, navigate)} />
            <div className="bank-transfer become-seller pt-3 pb-5">
                <Form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="container">
                        <Row>
                            <Col xs={12}>
                                <label>Upload Images</label>
                            </Col>

                            <Col xs={6}>
                                <ThemeFileUpload
                                    name="uploadImage"
                                    errors={errors}
                                    control={control}
                                    isLabel
                                    label="Upload Image"
                                    className={"mt-3"}
                                    icon={require('./../assets/images/gallery-add.png')}
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: validationText.general,
                                //     }
                                // }}
                                />
                            </Col>
                            <Col xs={6}>
                                <ThemeFileUpload
                                    name="uploadVideo"
                                    errors={errors}
                                    control={control}
                                    isLabel
                                    label="Upload Video"
                                    className={"mt-3"}
                                    icon={require('./../assets/images/video.png')}
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: validationText.general,
                                //     }
                                // }}
                                />
                            </Col>
                        </Row>

                        <ThemeInput
                            name="superAdminId"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="Admin ID (provided by Super Admin)"
                            className={"mt-3"}
                            placeholder={"Please Input the Super Admin ID"}
                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: validationText.general,
                        //     },
                        //     minLength: {
                        //         value: validation.nameMin,
                        //         message: validationText.nameMin,
                        //     },
                        //     maxLength: {
                        //         value: validation.nameMax,
                        //         message: validationText.nameMax,
                        //     },
                        // }}
                        />

                        <ThemeInput
                            name="admin_name"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="Admin Name"
                            className={"mt-3"}
                            placeholder={"Type here"}
                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: validationText.general,
                        //     },
                        //     minLength: {
                        //         value: validation.nameMin,
                        //         message: validationText.nameMin,
                        //     },
                        //     maxLength: {
                        //         value: validation.nameMax,
                        //         message: validationText.nameMax,
                        //     },
                        // }}
                        />

                        <ThemeInput
                            name="name"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="Your Name"
                            className={"mt-3"}
                            placeholder={"Type here"}
                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: validationText.general,
                        //     },
                        //     minLength: {
                        //         value: validation.nameMin,
                        //         message: validationText.nameMin,
                        //     },
                        //     maxLength: {
                        //         value: validation.nameMax,
                        //         message: validationText.nameMax,
                        //     },
                        // }}
                        />

                        <PhoneInput
                            className="mt-3"
                            specialLabel="Admin Contact/WhatsApp Number"
                            country={'pk'}
                            value={phone}
                            onChange={(e) => setPhone(e)}
                            placeholder="Type here"
                        />

                        <ThemeInput
                            name="eMail"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="Admin E-Mail"
                            className={"mt-3"}
                            placeholder={"Type here"}
                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: validationText.general,
                        //     },
                        //     maxLength: {
                        //         value: validation.emailMax,
                        //         message: validationText.nameemailMax,
                        //     },
                        // }}
                        />

                        <ThemeInput
                            name="admin"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="How many Agency do you have?"
                            className={"mt-3"}
                            placeholder={"5 Agency"}
                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: validationText.general,
                        //     },
                        //     minLength: {
                        //         value: validation.nameMin,
                        //         message: validationText.nameMin,
                        //     },
                        //     maxLength: {
                        //         value: validation.nameMax,
                        //         message: validationText.nameMax,
                        //     },
                        // }}
                        />


                        <Row className={`mt-3`}>
                            <Col xs={12}>
                                <label>Upload CNIC*</label>
                            </Col>
                            <Col xs={6}>
                                <ThemeFileUpload
                                    name="uploadFrontCnic"
                                    errors={errors}
                                    control={control}
                                    isLabel
                                    label="Front"
                                    icon={require('./../assets/images/gallery-add.png')}
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: validationText.general,
                                //     }
                                // }}
                                />
                            </Col>
                            <Col xs={6}>
                                <ThemeFileUpload
                                    name="uploadBackCnic"
                                    errors={errors}
                                    control={control}
                                    isLabel
                                    label="Back"
                                    icon={require('./../assets/images/gallery-add.png')}
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: validationText.general,
                                //     }
                                // }}
                                />
                            </Col>
                        </Row>

                        <Row className={`mt-3`}>
                            <Col xs={12}>
                                <label>Utility Bills*</label>
                            </Col>
                            <Col xs={12}>
                                <ThemeFileUpload
                                    name="utilityBills"
                                    errors={errors}
                                    control={control}
                                    isLabel
                                    label="Add Images"
                                    icon={require('./../assets/images/gallery-add.png')}
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: validationText.general,
                                //     }
                                // }}
                                />
                            </Col>
                        </Row>

                        <Row className={`mt-3`}>
                            <Col xs={6}>
                                <ThemeInput
                                    name="city"
                                    errors={errors}
                                    control={control}
                                    type={"text"}
                                    isLabel
                                    label="City"
                                    className={"mt-3"}
                                    placeholder={"Add Here"}
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: validationText.general,
                                //     },
                                //     minLength: {
                                //         value: validation.nameMin,
                                //         message: validationText.nameMin,
                                //     },
                                //     maxLength: {
                                //         value: validation.nameMax,
                                //         message: validationText.nameMax,
                                //     },
                                // }}
                                />
                            </Col>
                            <Col xs={6}>
                                <ThemeInput
                                    name="zipCode"
                                    errors={errors}
                                    control={control}
                                    type={"text"}
                                    isLabel
                                    label="Zip Code"
                                    className={"mt-3"}
                                    placeholder={"Add Here"}
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: validationText.general,
                                //     },
                                //     minLength: {
                                //         value: validation.nameMin,
                                //         message: validationText.nameMin,
                                //     },
                                //     maxLength: {
                                //         value: validation.nameMax,
                                //         message: validationText.nameMax,
                                //     },
                                // }}
                                />
                            </Col>
                        </Row>
                        <ThemeCodeInput
                            name="name"
                            label="WhatsApp Code"
                            errors={errors}
                            control={control}
                            type={"text"}
                            placeholder={"Type here"}

                        />
                        {/* <ThemeInput
                            name="whatsAppCode"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="WhatsApp Code"
                            className={"mt-3"}
                            placeholder={"Type here"}

                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: validationText.general,
                        //     },
                        //     minLength: {
                        //         value: validation.nameMin,
                        //         message: validationText.nameMin,
                        //     },
                        //     maxLength: {
                        //         value: validation.nameMax,
                        //         message: validationText.nameMax,
                        //     },
                        // }}
                        /> */}

                        <ThemeInput
                            name="bankName"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="Bank Name"
                            className={"mt-3"}
                            placeholder={"Type here"}
                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: validationText.general,
                        //     },
                        //     minLength: {
                        //         value: validation.nameMin,
                        //         message: validationText.nameMin,
                        //     },
                        //     maxLength: {
                        //         value: validation.nameMax,
                        //         message: validationText.nameMax,
                        //     },
                        // }}
                        />

                        <ThemeInput
                            name="ibnNumber"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="IBN Number"
                            className={"mt-3"}
                            placeholder={"Type here"}
                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: validationText.general,
                        //     },
                        //     minLength: {
                        //         value: validation.nameMin,
                        //         message: validationText.nameMin,
                        //     },
                        //     maxLength: {
                        //         value: validation.nameMax,
                        //         message: validationText.nameMax,
                        //     },
                        // }}
                        />

                        <ThemeInput
                            name="bankAddress"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="Bank Address"
                            className={"mt-3"}
                            placeholder={"Type here"}
                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: validationText.general,
                        //     },
                        //     minLength: {
                        //         value: validation.nameMin,
                        //         message: validationText.nameMin,
                        //     },
                        //     maxLength: {
                        //         value: validation.nameMax,
                        //         message: validationText.nameMax,
                        //     },
                        // }}
                        />
                    </div>
                    <FooterSaveButton disabled={loading} text={"Save"} />
                </Form>
            </div>
        </div>

    )
}

export default BecomeAdmin
