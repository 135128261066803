import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { LuArrowDownUp } from "react-icons/lu";
import { PiWarningCircleLight } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import profile from "../assets/images/profile.png";
import ChooseButton from "../components/ChooseButton";
import CurrentBalance from "../components/CurrentBalance";
import StarText from "../components/StarText";
import FooterSaveButton from "../components/Theme/FooterButton";
import ThemeOffcanvas from "../components/Theme/ThemeOffcanvas";
import TopWalletHeader from "../components/TopWalletHeader";
import WithdrawlBody from "../components/offcanvasBody/WithDrawlBody";
import { ROUTES } from "../utils/app-constants";
import { Diamond, RedCapsule, RedSeprator } from "../utils/svg";
import SelectRadio from "../components/SelectRadio";

const WithDrawl = () => {
    const navigate = useNavigate()

    const [activeType] = useState(1)
    const [show, setShow] = useState(false)
    const [selectedToggle, setSelectedToggle] = useState(2)

    const setActiveType = () => {
        window?.ReactNativeWebView?.postMessage('coins');
    }

    const setActive = () => {
        navigate(ROUTES.withdrawl)
    }

    const handleToggle = () => {
        navigate(ROUTES.exchange)
    }

    return (
        <div className="withdrawl">
            <div className="container">
                <TopWalletHeader page={"Withdrawl"} className={""} />
            </div>
            <div className="container inner">
                <div className="flex-custom-space-center w-100 mb-3">
                    <ChooseButton className="mt-3 coins-choose-btn" first={"Coin"} second={"Diamond"} active={activeType} setActive={setActiveType} />
                </div>
                <CurrentBalance className="balance mt-2" text={
                    <div className="diamond-count flex-custom-space-start">
                        <Diamond />
                        12,457,567
                    </div>
                } heading={"Current Balance"} />
                <div className="flex-custom-space-center mt-3">
                    <RedSeprator />
                </div>
                <div className="select-toggle d-flex mt-3">
                    <RedCapsule />
                    <SelectRadio className="ms-2" selected={selectedToggle} setSelected={handleToggle} />
                </div>
                {/* <div className="heading mt-4 flex-custom-space-start">
                    <RedCapsule />
                    <h2>Withdrawal</h2>
                </div> */}
                <div className="flex-custom-space-center">
                    <ChooseButton className="mt-3" first={"Choose"} second={"Custom Amt"} active={1} setActive={setActive} />
                </div>
                <div className="detail-box mt-4">
                    <div className="heading flex-custom-space-between">
                        <div className="my-account-text">My Account</div>
                        <div className="text cursor" onClick={() => navigate(ROUTES.myAccounts)}>
                            Change
                        </div>
                    </div>
                    <div className="details d-flex align-items-center mt-2">
                        <img src={profile} className="image-fluid object-fit-contain" />
                        <div className="heading ms-2 d-flex flex-column">
                            <p>Account Name:</p>
                            <p>Bank Name:</p>
                            <p>Account Number:</p>
                        </div>
                        <div className="content ms-3 d-flex flex-column">
                            <p>MIGO LIVE PK</p>
                            <p>EasyPaisa</p>
                            <p>8765432345678</p>
                        </div>
                    </div>
                </div>
                <div className="enter-diamonds mt-4 d-flex align-items-center justify-content-center flex-column">
                    <h2>Enter Diamonds</h2>
                    <h1>0</h1>
                    <LuArrowDownUp color="#FF4B62" size={23} className="mt-1 mb-1" />
                    <span>0</span>
                    <p>USD</p>
                </div>
                <StarText text={"Official/Company Withdrawal"} />
                <div className="warn d-flex mt-3">
                    <div>
                        <PiWarningCircleLight size={24} color="#FF3B52" />
                    </div>
                    <p>Once your withdraw request is approved, you'll receive the fee within around 15 working days according to the payment method you chose.</p>
                </div>
                <div className="agree mt-4">
                    <Form>
                        <div key={`default`} className="mb-3">
                            <Form.Check
                                type={"checkbox"}
                                // id={`default-${type}`}
                                label={<div className={"agree-text"}>I agree to the <Link to="/dashboard">Terms & Conditions</Link> by clicking confirm.</div>}
                            />
                        </div>
                    </Form>
                </div>
            </div>
            <FooterSaveButton text={"Withdraw"} onClick={() => setShow(true)} />
            <ThemeOffcanvas show={show} onHide={() => { setShow(!show) }} body={<WithdrawlBody setShow={setShow} />} />
        </div>
    )
}

export default WithDrawl;