import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import RequestRow from "../components/RequestRow";
import { useNavigate } from "react-router-dom";

function AgencyApplication() {
    const navigate = useNavigate()

    const handleBackButton = () => {
        navigate(-1)
    }

    return (
        <div className="admin-application bg-pink-light-gradient">
            {
                // MARK: Top Bar
            }
            <div className="top-bar flex-custom-space-between">
                <div className="flex-custom-space-start" onClick={handleBackButton}>
                    <MdKeyboardBackspace size={24} />
                </div>
                <h1 className="mx-auto">Agency Apply</h1>
            </div>
            {
                // MARK: Request
            }
            <div className="requests">
                <h2>Requests</h2>
            </div>

            <div className="scrollable-content">
                {Array.from({ length: 4 }).map(() => {
                    return (
                        <div className="request-card">
                            <RequestRow text1={"Admin Name:"} text2={"Zubi"} />
                            <RequestRow text1={"Id:"} text2={"987239847"} />
                            <RequestRow text1={"Name:"} text2={"Zubina"} />
                            <RequestRow text1={"Gender:"} text2={"Female"} />
                            <RequestRow text1={"Phone:"} text2={"034895702"} />
                            <RequestRow text1={"Email:"} text2={"asdfsg@gmail.com"} />
                            <div className="d-flex justify-content-around my-3">
                                <div className="btn gradient-green">Agree</div>
                                <div className="btn gradient-red">Refuse</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default AgencyApplication;
