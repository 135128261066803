import React from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { Users } from '../utils/svg'
import { GrShare } from "react-icons/gr";
import { ROUTES } from '../utils/app-constants';
import { handleBackButton } from '../utils/helper';
import { Image } from 'react-bootstrap';

function TopWalletHeader({ page, className }) {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div className={`top-bar-wallet-header flex-custom-space-between ${className}`}>
            <div className={"icons flex-custom-space-start"}>
                <MdKeyboardBackspace size={24} onClick={() => handleBackButton(location, navigate)} />
                <h1 className="ms-3">{page}</h1>
            </div>
            <div className={"flex-custom-space-center gap-3"}>
                <div className={'seller text flex-custom-space-center'}><p>Seller</p> <Users /></div>
                <span onClick={() => navigate(ROUTES.walletlHistory)} ><Image src={require('./../assets/images/timer.png')} width={18} height={18} /></span>
                {/* <GrShare className='ms-2' color='rgba(16, 24, 40, 1)' size={18} onClick={() => navigate(ROUTES.walletlHistory)} /> */}
            </div>
        </div>
    )
}

export default TopWalletHeader
