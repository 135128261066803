import React from 'react'
import { BsGraphUp } from 'react-icons/bs'
import { FaCalendarDays } from 'react-icons/fa6'
import { GiCutDiamond } from 'react-icons/gi'
import { MdKeyboardArrowDown, MdKeyboardBackspace } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as PDFIcon } from "../assets/images/pdf.svg"
import ContentContainer from '../components/ContentContainer'
import TeamCard from '../components/TeamCard'
import TopBarCustom from '../components/TopBarCustom'
import { handleBackButton } from '../utils/helper'

const HostCenter = () => {
    const navigate = useNavigate()
    const location = useLocation()


    const data = [
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
        { id: 1, date: "17/05/2024", dur: "65M", valid: "0", room: "52" },
    ]
    return (
        <div className='bg-pink'>
            {/* <TopBarCustom text={"Host Record"} onClick={handleBackButton} /> */}
            <div className='top-bar-middle px-3 d-flex w-100'>
                <MdKeyboardBackspace size={24} onClick={() => handleBackButton(location, navigate)} />
                <h1 className='text-center center-text w-100'>Host Center</h1>
            </div>
            <div className='host-success host-center container pb-5'>
                <div className='content'>
                    {/** MARK:ADMIN CARD */}
                    <TeamCard
                        bgColor="#fff"
                        verified
                        shadow
                        title='Gojo Satoru Team'
                        description='ID 3023565256'
                        image={require('../assets/images/person-1.png')}
                        className="mt-4 mb-3 w-100 host-record-user"

                        authorType="Admin"
                        authorName="Kathykatie"

                        agencyType="Agency code:"
                        agencyName="345"
                    />

                    {/** MARK:CALENDER */}
                    <div className='w-100 d-flex flex-row align-items-center justify-content-between px-3 content-container-comp'>
                        <ContentContainer
                            borderRadius='10px'
                            backgroundColor='#fffff'
                            classes='d-flex flex-row align-items-center gap-2'
                        >
                            <FaCalendarDays size={16} />
                            <p className='fs-6 m-0'>
                                This Month
                            </p>
                            <MdKeyboardArrowDown size={26} />
                        </ContentContainer>
                        <ContentContainer
                            classes='px-4 pt-2 pb-2'
                            borderRadius='13px'
                            background='linear-gradient(286.17deg, #FF1C3D 0%, #FF516A 100%)'
                        >
                            <p className='text-white d-flex flex-row gap-3 fs-4'><span>05-08-2024</span> <span>05-08-2024</span></p>
                        </ContentContainer>
                    </div>


                    {/**  MARK:STATS */}
                    <div className='statsContainer w-100 mt-3'>
                        <ContentContainer
                            borderRadius='15px'
                            background='linear-gradient(98.59deg, #FF6F71 0.35%, #E54649 99.65%)'
                            classes='w-100 px-3 pt-4 pb-4 main-container-total'
                        >
                            <div className='titleContainer mb-4 d-flex flex-row align-items-center justify-cotent-between gap-2 text-white'>
                                <BsGraphUp size={20} />
                                <h5 className='fw-bold'>Total</h5>
                            </div>
                            <ContentContainer
                                classes='w-100 p-2 d-flex flex-row gap-2 sub-main-container-total'
                                background='#FEE2E2'
                                borderRadius='15px'
                            >
                                <ContentContainer
                                    classes='w-100 px-2 pt-1 pb-1 d-flex flex-column align-items-start justify-content-start inner-card-total'
                                    background='#FEF2F2'
                                    borderRadius='10px'
                                >
                                    <GiCutDiamond color='#FFB800' size={"36px"} />
                                    <h3 className='mt-1 mb-1'>2000</h3>
                                    <p className='m-0 mt-1'>Voice Total Personals<br />Diamonds</p>
                                </ContentContainer>

                                <ContentContainer
                                    classes='w-100 px-2 pt- pb-1 d-flex flex-column align-items-start justify-content-start inner-card-total'
                                    background='#FEF2F2'
                                    borderRadius='10px'
                                >
                                    <GiCutDiamond color='#FFB800' size={"36px"} />
                                    <h3 className='mt-1 mb-1'>234</h3>
                                    <p className='m-0 mt-1'>Room Total<br />Diamond</p>
                                </ContentContainer>

                            </ContentContainer>

                            <div className='w-100 mt-3 d-flex flex-row gap-2 text-center valid-text'>

                                <ContentContainer
                                    classes='w-100 p-2'
                                    background='#FEF2F2'
                                    borderRadius='15px'
                                >
                                    <p className='m-0 fs-6'>Valid day : 2</p>
                                </ContentContainer>
                                <ContentContainer
                                    classes='w-100 p-2'
                                    background='#FEF2F2'
                                    borderRadius='15px'
                                >
                                    <p className='m-0 fs-6'>Total Duration(mins): 262</p>
                                </ContentContainer>
                            </div>
                        </ContentContainer>


                        {/** MARK:PDF */}
                        <div className='policyDocumentContainer my-3 w-100'>
                            <ContentContainer
                                classes='w-100 p-3 d-flex flex-row align-items-center gap-3'
                                boxShadow='0px 4px 35px 0px #00000012'
                                borderRadius='15px'
                            >
                                <ContentContainer
                                    background='#FEE2E2'
                                    borderRadius='15px'
                                    classes='p-2'
                                >
                                    <PDFIcon width={50} height={50} />
                                </ContentContainer>
                                <div className='d-flex flex-column'>
                                    <h6 className='mb-1 fs-6 fw-bold'>Fogo Live Host Policy</h6>
                                    <p className='m-0'>Policy For Host</p>
                                </div>

                            </ContentContainer>
                        </div>
                    </div>


                    {/** MARK:PDF */}
                    <div className='tabContainer w-100 mt-1 mb-3'>
                        <ContentContainer
                            classes='w-100 p-2 d-flex flex-row align-items-center gap-2'
                            borderRadius='46px'
                            background='#F4F4F5'
                        >
                            <ContentContainer
                                borderRadius='30px'
                                background='#FF516A'
                                classes='w-100 text-white py-2 text-center'
                            >
                                <p className='fs-6'>Personal Income</p>
                            </ContentContainer>
                            <ContentContainer
                                borderRadius='30px'
                                background='#FFFFFF'
                                classes='w-100 py-2 text-center'
                            >
                                <p className='fs-6'>Room Income</p>
                            </ContentContainer>

                        </ContentContainer>
                    </div>

                    {/** MARK:TABLE */}
                    <div className='tableContainer w-100 mt-1 d-flex flex-column'>
                        <h6 className='theme-heading'>Details</h6>
                        <div class="table-responsive my-2">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Duration(mins)</th>
                                        <th scope="col">Valid Day</th>
                                        <th scope="col">Personal Room Diamond</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((dt) => (
                                        <tr key={dt?.id}>
                                            <td>{dt?.date}</td>
                                            <td>{dt?.dur}</td>
                                            <td>{dt?.valid}</td>
                                            <td>{dt?.room}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* scrol content here */}
                </div>
            </div >
        </div>

    )
}

export default HostCenter