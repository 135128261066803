import React, { useState } from "react";
import { Button, Col, Image, Row, Tab, Tabs } from "react-bootstrap";
import CurrentDate from "./../components/CurrentDate";
// import Host from "./../components/Host";
import { useLocation, useNavigate } from "react-router-dom";
import Host from "../components/Host";
import ThemeRequestsBtn from "../components/Theme/ThemeRequestsBtn";
import TopBarCustom from "../components/TopBarCustom";
import { ROUTES } from "../utils/app-constants";
import IncomeCard from "./../components/IncomeCard";
import SearchBar from "./../components/SearchBar";
import TeamCard from "./../components/TeamCard";
import UserCard from "./../components/UserCard";
import { handleBackButton } from "../utils/helper";
import { MdKeyboardBackspace } from "react-icons/md";

const AgencyDetails = () => {
    const [adminTab, setAdminTab] = useState('video');
    const navigate = useNavigate();
    const location = useLocation()


    const handleViewHost = () => {
        navigate(ROUTES.hostCenter)
    }

    const handleViewRequest = () => {
        navigate(ROUTES.hostRequest)
    }

    return (
        <div className='bg-light-pink'>
            <div className='top-bar-middle px-3 d-flex w-100'>
                <MdKeyboardBackspace size={24} onClick={() => handleBackButton(location, navigate)} />
                <h1 className='text-center center-text w-100'>Agency Details</h1>
            </div>
            {/* <TopBarCustom text={"Agency Details"} onClick={() => handleBackButton(location, navigate)} >
                <ThemeRequestsBtn handleViewRequest={handleViewRequest} text={"20 Requests"} />
            </TopBarCustom> */}
            <div className="container agency-details pt-3 pb-5">
                <div className="py-3">
                    <div className="mb-3">
                        <UserCard
                            title={`Admin Name Here`}
                            description={`ID 3023565256`}
                            image={require('./../assets/images/person-1.png')}
                            className={`mb-3 border-0`}
                            authorType="Admin"
                            authorName="Kathykatie"
                            agencyType="Agency code:"
                            agencyName="345"
                            verified={true}
                        />
                    </div>

                    <div className="mb-3">
                        <CurrentDate />
                    </div>

                    <div className="mb-3">
                        <Row className="gx-3">
                            <Col xs={6}>
                                <IncomeCard
                                    bgColor="#FFEDD5"
                                    title="45780"
                                    description="Total Income Month"
                                    image={require('./../assets/images/money-send.png')}
                                    className="border-0"
                                />
                            </Col>
                            <Col xs={6}>
                                <IncomeCard
                                    bgColor="#FEF3C7"
                                    title="45780"
                                    description="Daily Income"
                                    image={require('./../assets/images/wallet.png')}
                                    className="border-0"
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className="mb-3">
                        <Host
                            title="My Hosts"
                            description="12"
                            image={require('./../assets/images/user-with-mark.png')}
                        />
                    </div>

                    <div className="mb-3">
                        <Tabs activeKey={adminTab} onSelect={(k) => setAdminTab(k)} className="agency-tabs">
                            <Tab eventKey="video" title="Video - 5">
                                <div className="mt-4 mb-2">
                                    <SearchBar />
                                </div>

                                <div className="mb-3" style={{ minHeight: '550px' }}>
                                    {[1, 1, 1]?.map(item => <TeamCard
                                        // type="danger"
                                        // bgColor="#fff"
                                        verified
                                        // shadow
                                        title='Gojo Satoru Team'
                                        description='ID 3023565256'
                                        image={require('./../assets/images/person-1.png')}
                                        className="border-0 mb-3"

                                    // authorType="Admin"
                                    // authorName="Kathykatie"

                                    // agencyType="Agency code:"
                                    // agencyName="345"

                                    // actionText="View Agencies"
                                    // actionFunc={() => alert('View Agencies')}
                                    >
                                        <div className="operation-container">
                                            <Button className="operation graph" onClick={handleViewHost}>
                                                <Image src={require('./../assets/images/edit.png')} />
                                            </Button>
                                            <Button className="operation">
                                                <Image src={require('./../assets/images/cross.png')} />
                                            </Button>
                                        </div>
                                    </TeamCard>
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey="audio" title="Audio - 7">
                                <div className="mt-4 mb-2">
                                    <SearchBar />
                                </div>

                                <div className="mb-3" style={{ minHeight: '550px' }}>
                                    {[1, 1, 1, 11, 1, 1, 1, 1]?.map(item => <TeamCard
                                        // type="danger"
                                        // bgColor="#fff"
                                        verified
                                        // shadow
                                        title='Gojo Satoru Team'
                                        description='ID 3023565256'
                                        image={require('./../assets/images/person-1.png')}
                                        className="border-0 mb-3"

                                    // authorType="Admin"
                                    // authorName="Kathykatie"

                                    // agencyType="Agency code:"
                                    // agencyName="345"

                                    // actionText="View Agencies"
                                    // actionFunc={() => alert('View Agencies')}
                                    >
                                        <div className="operation-container">
                                            <Button className="operation graph" onClick={handleViewHost}>
                                                <Image src={require('./../assets/images/edit.png')} />
                                            </Button>
                                            <Button className="operation">
                                                <Image src={require('./../assets/images/cross.png')} />
                                            </Button>
                                        </div>
                                    </TeamCard>
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
            {/* </AppLayout> */}
        </div>
    )
}
export default AgencyDetails;